.login-container {
  max-width: 400px;
  padding-left: 30px;
  padding-right: 30px;
}

.login-box {
  margin-top: 13px;
  margin-bottom: 13px;
  cursor: default;
  height: 150px;
  display: flex;
  justify-content: center;
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-title {
  background-color: #ec3b83;
  color: white;
  border-radius: 7px;
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
  margin-top: 98px;
}

.login-title:hover {
  cursor: default;
}

.login-input {
  height: 2rem;
  width: 65vw;
  max-width: 300px;
  font-size: 16px;
  border: solid 2px black;
  border-radius: 7px;
  background-color: whitesmoke;
  text-overflow: ellipsis;
}

.login-input:focus {
  outline: none;
  border-color: #ec3b83;
}

.login-input::placeholder {
  font-style: italic;
  color: grey;
}

#login-form-top-input {
  margin-bottom: 10px;
}

.logged-in-msg {
  margin-left: 30px;
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.loggingin-container {
  text-align: center;
}

.loggingin {
  height: 25px;
  margin-left: auto;
  margin-right: auto;
}

.register-question {
  color: white;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.forgot-question {
  color: white;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.forgot-question > span {
  transition: 0.2s;
}

.forgot-question > span:hover {
  color: #ec3b83;
  cursor: pointer;
}

#go-back {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}
