.mobile__footer-body {
  margin-left: -80px;
  background-color: black;
  min-height: 300px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  font-family: 'Lato';
}

.mobile__footer-body > div {
  margin-top: 15px;
  margin-left: 50px;
  width: 200px;
}

.social-media-icons-footer {
  margin-top: 10px;
  margin-right: 10px;
  height: 25px;
}
