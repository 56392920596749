.about-container,
.mobile-about-container {
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
}

.about-box {
  color: black;
  border-radius: 7px;
  min-height: 280px;
  display: flex;
  flex-direction: row;
}

.about-box > p:not(.editorial-board-p) {
  padding: 10px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  width: 50%;
}

.about-title {
  background-color: #ec3b83;
  color: white;
  border-radius: 7px;
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
  display: flex;
}

.about-title:hover {
  cursor: default;
}

.about-box-container {
  margin-right: 10px;
  max-width: 600px;
}

#top-about-box {
  margin-bottom: 5px;
}

#mobile-about {
}

#desktop-about {
  margin-left: 3vw;
}

#about-p {
  width: 100%;
}

.editorial-board-p {
  margin-left: 5px;
  margin-bottom: 100px;
  margin-left: 15px;
  margin-right: 15px;
  line-height: 1.3;
  font-size: 14px;
  font-weight: bold;
  color: black;
}

.editorial-board-member-span {
  font-style: italic;
}

.ed-board-name {
  color: #ec3b83;
}
