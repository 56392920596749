.contact-container,
.mobile-contact-container {
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
}

.contact-box {
  background-color: white;
  color: black;
  border-radius: 7px;
  min-height: 300px;
}

.contact-box > p {
  padding: 10px;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
}

.contact-title {
  background-color: #ec3b83;
  color: white;
  border-radius: 7px;
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
  display: flex;
}

.contact-title:hover {
  cursor: default;
}

.contact-box-container {
  margin-right: 10px;
  max-width: 400px;
}

#top-contact-box {
  margin-bottom: 5px;
}

#mobile-contact {
}

#desktop-contact {
  margin-left: 30vw;
}
