.mobile-home-container {
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 75px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: center;
}

.mobile-current-issue-container {
  width: 100%;
  width: 430px;
  margin-right: 10px;
}

.mobile-current-issue-box {
  height: 100%;
  border-radius: 7px;
  background-color: white;
}

.mobile-content-titles {
  display: flex;
  background-color: #ec3b83;
  border-radius: 7px;
  padding: 10px;
  margin-bottom: 12px;
  margin-top: 50px;
}

.mobile-content-titles:hover {
  cursor: default;
}

.mobile-content-titles > span {
  font-weight: 500;
  font-size: 12px;
  color: white;
}

.mobile-issue-img {
  margin-top: 10px;
  margin-left: 15px;
  margin-bottom: 30px;
  max-width: 170px;
  min-height: 240px;
  transition: 1s;
  box-shadow: 10px 10px #ec3b83;
}

.mobile-featured-content-container {
  width: 100%;
}

.mobile-featured-content-box {
  display: flex;
  justify-content: center;
  height: 450px;
  background-color: white;
  border-radius: 7px;
}

#bottom-mobile-fc-box {
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
}

.twitter-feed-container {
  display: flex;
  justify-content: center;
}

.twitter-feed-box {
  display: flex;
  justify-content: center;
  background-color: white;
  border-radius: 7px;
  width: 430px;
  min-height: 520px;
  margin-top: 40px;
  margin-left: 30px;
  margin-right: 30px;
  text-align: center;
}

#about-issue > p {
  padding: 10px;
  line-height: 1.3;
  font-size: 14px;
  font-weight: bold;
  color: #36454f;
  font-family: Arial, Helvetica, sans-serif;
}

.offer-mobile-container {
  display: flex;
  justify-content: center;
  margin-left: 30px;
  margin-right: 30px;
}

.offer-mobile-home {
  margin-top: 100px;
  max-height: 550px;
}

.offer-mobile-home-landscape {
  margin-top: 100px;
  width: 80vw;
}

.mobile-home-bottom-container {
  display: flex;
}

.mobile-home-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ewma-ad-mobile {
  max-width: 80vw;
}

.ewma-ad-mobile:hover {
  cursor: pointer;
}
