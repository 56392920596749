.terms-and-conditions-container {
  margin-left: 30px;
  margin-right: 30px;
  background-color: white;
  margin-bottom: -100px;
}

.terms-and-conditions-title {
  background-color: #ec3b83;
  color: white;
  border-radius: 7px;
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
  margin-bottom: 20px;
}

.terms-and-conditions-title:hover {
  cursor: default;
}
