.header-img {
  height: 8rem;
  margin-top: 1rem;
  margin-left: 30px;
}

.options-bar {
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
  margin-bottom: 3rem;
}

.nav-section {
  margin-bottom: 1rem;
}

.options-page {
  color: whitesmoke;
  font-size: 16px;
  font-weight: 600;
  transition: 0.2s;
}

.options-page:hover {
  cursor: pointer;
  color: #ec3b83;
}

.options-page-active {
  font-size: 16px;
  font-weight: 600;
  color: #ec3b83;
}

.options-links {
  margin-right: 30px;
  margin-left: 20px;
}

#logout-span {
  margin-left: 20px;
}

.desktop-tagline-gif {
  margin-top: 50px;
  text-align: center;
}

.desktop-tagline-gif > img {
  height: 20px;
  margin-left: auto;
  margin-right: auto;
}
