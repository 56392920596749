.nav-bar {
  margin-bottom: 30px;
  margin-left: 10px;
  margin-right: 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.nav-container {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.nav-button {
  width: 10rem;
  max-height: auto;
  margin-left: 3px;
  margin-right: 3px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 7px;
  background-color: #ec3b83;
  display: flex;
  color: white;
  justify-content: center;
}

.nav-button > span {
  color: inherit;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Lato';
}

.nav-button:hover {
  cursor: pointer;
}

.nav-button:active {
  background-color: white;
  color: #ec3b83;
}

.social-media-icons {
  height: 25px;
  margin-left: 5px;
  transition: 0.1s;
}

.social-media-icons:hover {
  transform: scale(1.05);
  cursor: pointer;
}

#instagram-icon {
  margin-left: 10px;
}

.social-media-home {
  margin-top: 2.4rem;
  margin-right: -30px;
}
